import React from 'react';
const colorbarStyle = {
  width: '100%',
  height: 8,
  borderRadius: 4,
};

const tableStyle = {
  width: '100%',
  fontSize: 10,
  textAlign: 'center',
};

export default function Legend({ src, length, range, unit }) {
  const [val, setVals] = React.useState();

  React.useEffect(() => {
    length--;
    let arr = [];
    let diff = range[1] - range[0];
    let incr = diff / length;

    for (let i = 0; i <= length; i++) {
      let value = range[0] + incr * i;
      arr.push(parseInt(value));
    }

    setVals([...arr]);
    // eslint-disable-next-line
  }, [range]);

  return (
    <>
      <img src={src} alt="" style={colorbarStyle} />
      {val && (
        <table style={tableStyle}>
          <tbody>
            <tr>
              {val.map(v => {
                return <td key={v}>{(v * 0.0001).toFixed(2)}</td>;
              })}
            </tr>
          </tbody>
        </table>
      )}
    </>
  );
}
