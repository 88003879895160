import React, { useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { AppContext } from 'App';
import { fetchLegend } from 'data';

// const parcels_url = {
//   tiles: [
//     'https://sy6s8ghoek.execute-api.eu-central-1.amazonaws.com/development/geoserver/LBSTCC2020/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=LBSTCC2020:parcels',
//   ],
//   tileSize: 256,
//   attribution:
//     '<a href="https://sentinel.esa.int/web/sentinel/home" target="blank">' +
//     'Contains modified Copernicus Sentinel data ' +
//     new Date().getFullYear() +
//     ' ' +
//     '</a>',
// };

const geoserver_url = process.env.REACT_APP_GEOSERVER;

const ParcelsLayer = ({ parcels }) => {
  const {
    actions: { setLegend },
    state: { classificationOpacity, mapbox, parcelLayer, selectedDate },
  } = React.useContext(AppContext);
  const [url, setUrl] = useState(false);

  React.useEffect(() => {
    mapbox && computeUrl();
    // eslint-disable-next-line
  }, [parcelLayer]);

  React.useEffect(() => {
    url && parcels && mapbox.moveLayer('parcels-wms-layer', 'parcels-geojson');
    // eslint-disable-next-line
  }, [url]);

  const computeUrl = async () => {
    try {
      const _url = `${geoserver_url}/${parcelLayer.workspace}/wms?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&transparent=true&width=256&height=256&layers=${parcelLayer.workspace}:${parcelLayer.layer}`;
      const legend = await fetchLegend({
        layer: parcelLayer.layer,
        workspace: parcelLayer.workspace,
      });
      setLegend(legend);

      if (url) {
        mapbox.getSource('parcels-wms').tiles = [_url];
        mapbox.style.sourceCaches['parcels-wms'].clearTiles();
        mapbox.style.sourceCaches['parcels-wms'].update(mapbox.transform);
        mapbox.triggerRepaint();
        selectedDate &&
          mapbox.moveLayer('tiles-wms-layer', 'parcels-wms-layer');
      } else {
        setUrl({
          tiles: [_url],
          tileSize: 256,
          attribution:
            '<a href="https://sentinel.esa.int/web/sentinel/home" target="blank">' +
            'Contains modified Copernicus Sentinel data ' +
            new Date().getFullYear() +
            ' ' +
            '</a>',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    url && (
      <Source id="parcels-wms" type="raster" {...url}>
        <Layer
          id="parcels-wms-layer"
          type="raster"
          source="parcels-wms"
          paint={{
            'raster-opacity':
              parcelLayer.layer === 'task1-tl' && classificationOpacity
                ? classificationOpacity
                : 1,
          }}
        />
      </Source>
    )
  );
};

export default ParcelsLayer;
