const checkboxOptions = [
  {
    sensor: 's2',
    title: 'Sentinel 2:',
    stats: [
      {
        name: 'b02_median',
        range: '0_1',
        title: 'B02',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: '#19EDFF',
      },
      {
        name: 'b03_median',
        range: '0_1',
        title: 'B03',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: '#13DBFA',
      },
      {
        name: 'b04_median',
        range: '0_1',
        title: 'B04',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: '#0DC9F6',
      },
      {
        name: 'b05_median',
        range: '0_1',
        title: 'B05',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: '#06B6F1',
      },
      {
        name: 'b06_median',
        range: '0_1',
        title: 'B06',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: '#00A4EC',
      },
      {
        name: 'b07_median',
        range: '0_1',
        title: 'B07',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: '#E7D4FC',
      },
      {
        name: 'b08_median',
        range: '0_1',
        title: 'B08',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: '#C5B1E6',
      },
      {
        name: 'b8a_median',
        range: '0_1',
        title: 'B8a',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: '#A48DD0',
      },
      {
        name: 'b11_median',
        range: '0_1',
        title: 'B11',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: '#826ABA',
      },
      {
        name: 'b12_median',
        range: '0_1',
        title: 'B12',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: '#6046A4',
      },
      {
        name: 'ndvi_median',
        range: '0_1',
        title: 'NDVI',
        checked: true,
        axis: 'y1',
        mode: 'markers',
        color: 'rgb(0, 118, 200)',
      },
      {
        name: 'index_ndti',
        range: '0_1',
        title: 'NDTI',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: 'rgb(134, 162, 179)',
      },
      {
        name: 'index_ccmi',
        range: '0_1',
        title: 'CCMI',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: 'rgb(85, 122, 143)',
      },
      {
        name: 'index_re8a6',
        range: '0_1',
        title: 'RE8A6',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: 'rgb(207, 219, 226)',
      },
      {
        name: 'index_ndi7',
        range: '0_1',
        title: 'NDI7',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: 'rgb(150,150,150)',
      },
    ],
  },
  {
    sensor: 'l8',
    title: 'Landsat 8:',
    stats: [
      {
        name: 'b02_median',
        range: '0_1',
        title: 'B02',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: 'rgba(62, 210, 206, 0.4)',
      },
      {
        name: 'b03_median',
        range: '0_1',
        title: 'B03',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: 'rgba(62, 210, 206, 0.55)',
      },
      {
        name: 'b04_median',
        range: '0_1',
        title: 'B04',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: 'rgba(62, 210, 206, 0.7)',
      },
      {
        name: 'b05_median',
        range: '0_1',
        title: 'B05',
        checked: false,
        axis: 'y1',
        mode: 'markers',
        color: 'rgba(62, 210, 206, 0.85)',
      },
      {
        name: 'ndvi_median',
        range: '0_1',
        title: 'NDVI',
        checked: true,
        axis: 'y1',
        mode: 'markers',
        color: 'rgba(53, 178, 174, 1)',
      },
    ],
  },
  {
    sensor: 's1db',
    title: 'Sentinel 1 db:',
    stats: [
      {
        name: 'vh_median',
        range: 'n5_n25',
        title: 'VH',
        checked: false,
        axis: 'y2',
        mode: 'lines',
        color: 'rgb(212, 13, 87)',
      },
      {
        name: 'vv_median',
        range: 'n5_n25',
        title: 'VV',
        checked: false,
        axis: 'y2',
        mode: 'lines',
        color: 'rgb(255, 177, 200)',
      },
      {
        name: 'vhovervv_median',
        range: '0_1',
        title: 'VV / VH',
        checked: false,
        axis: 'y3',
        mode: 'lines',
        color: '#CF6766',
      },
    ],
  },
  {
    sensor: 's1coh',
    title: 'Sentinel 1 coherence:',
    stats: [
      {
        name: 'coh_median',
        range: '0_1',
        title: 'Coherence',
        checked: true,
        axis: 'y1',
        mode: 'lines',
        color: 'rgb(9, 51, 75)',
      },
    ],
  },
];

export { checkboxOptions };
