export const mapping = [
  {
    b02_median: {
      name: 'Sen2 - B02',
      axis: 'y1',
      mode: 'marker',
      color: '#19EDFF',
    },
    b03_median: {
      name: 'Sen2 - B03',
      axis: 'y1',
      mode: 'marker',
      color: '#13DBFA',
    },
    b04_median: {
      name: 'Sen2 - B04',
      axis: 'y1',
      mode: 'marker',
      color: '#0DC9F6',
    },
    b05_median: {
      name: 'Sen2 - B05',
      axis: 'y1',
      mode: 'marker',
      color: '#06B6F1',
    },
    b06_median: {
      name: 'Sen2 - B06',
      axis: 'y1',
      mode: 'marker',
      color: '#00A4EC',
    },
    b07_median: {
      name: 'Sen2 - B07',
      axis: 'y1',
      mode: 'marker',
      color: '#E7D4FC',
    },
    b08_median: {
      name: 'Sen2 - B08',
      axis: 'y1',
      mode: 'marker',
      color: '#C5B1E6',
    },
    b8a_median: {
      name: 'Sen2 - B8a',
      axis: 'y1',
      mode: 'marker',
      color: '#A48DD0',
    },
    b11_median: {
      name: 'Sen2 - B11',
      axis: 'y1',
      mode: 'marker',
      color: '#826ABA',
    },
    b12_median: {
      name: 'Sen2 - B12',
      axis: 'y1',
      mode: 'marker',
      color: '#6046A4',
    },
    ndvi_median: {
      name: 'NDVI',
      axis: 'y1',
      mode: 'marker',
      color: 'rgb(0, 118, 200)',
    },
    index_ndti: {
      name: 'NDTI',
      axis: 'y1',
      mode: 'marker',
      color: 'rgb(134, 162, 179)',
    },
    index_ccmi: {
      name: 'CCMI',
      axis: 'y1',
      mode: 'marker',
      color: 'rgb(85, 122, 143)',
    },
    index_re8a6: {
      name: 'RE8A6',
      axis: 'y1',
      mode: 'marker',
      color: 'rgb(207, 219, 226)',
    },
    index_ndi7: {
      name: 'NDI7',
      axis: 'y1',
      mode: 'marker',
      color: 'rgb(150,150,150)',
    },
  },
  {
    b02_median: {
      name: 'L8 - B02',
      axis: 'y1',
      mode: 'marker',
      color: 'rgba(62, 210, 206, 0.4)',
    },
    b03_median: {
      name: 'L8 - B03',
      axis: 'y1',
      mode: 'marker',
      color: 'rgba(62, 210, 206, 0.55)',
    },
    b04_median: {
      name: 'L8 - B04',
      axis: 'y1',
      mode: 'marker',
      color: 'rgba(62, 210, 206, 0.7)',
    },
    b05_median: {
      name: 'L8 - B05',
      axis: 'y1',
      mode: 'marker',
      color: 'rgba(62, 210, 206, 0.85)',
    },
    ndvi_median: {
      name: 'L8 - NDVI',
      axis: 'y1',
      mode: 'marker',
      color: 'rgba(53, 178, 174, 1)',
    },
  },
  {
    vh_median: {
      name: 'VH',
      axis: 'y2',
      mode: 'marker',
      color: 'rgb(212, 13, 87)',
    },
    vv_median: {
      name: 'VV',
      axis: 'y2',
      mode: 'marker',
      color: 'rgb(255, 177, 200)',
    },
    vhovervv_median: {
      name: 'VV / VH',
      axis: 'y3',
      mode: 'marker',
      color: '#CF6766',
    },
  },
  {
    coh_median: {
      name: 'Coherence',
      axis: 'y1',
      mode: 'lines',
      color: 'rgb(9, 51, 75)',
    },
  },
];
