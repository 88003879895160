import {
  directionColumn,
  justifyCenter,
  alignCenter,
} from 'styles/mixins';

export default () => ({
  minorLoading: {
    position: 'fixed',
    width: '100vw',
    top: 0,
    height: 4,
    zIndex: '2000',
    backgroundColor: 'white',
  },
  majorLoading: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: '2000',
    ...directionColumn,
    ...justifyCenter,
    ...alignCenter,
    backgroundColor: 'rgba(13,57,88,0.9)',
    color: 'white',
  },
});
