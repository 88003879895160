import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import {
  Accordion,
  AccordionSummary,
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DHITheme from 'styles/theme';

import CustomTable from 'components/Table';
import EventMarkers from 'components/EventMarkers';
import Legend from 'components/Legend';
import Login from 'components/Login';

import 'styles/scrollbar.css';
import { AppContext } from 'App';

const { primary } = DHITheme.palette;

const parcelLayers = [
  {
    title: 'Overview',
    workspace: 'LBSTCC2020',
    layer: 'parcels-tl',
  },
  {
    title: 'Task 1: Crop Category Classification',
    workspace: 'LBSTCC2020',
    layer: 'task1-tl',
  },
  {
    title: 'Task 2: Crop Classification for Activity Requirement',
    workspace: 'LBSTCC2020',
    layer: 'activity-crop-classification-08-02',
  },
  {
    title: 'Task 6: Non-Compliance Classification',
    workspace: 'LBSTCC2020',
    layer: 'task-6-non-compliance',
  },
  {
    title: 'Task 7: Maize Harvest Detection',
    workspace: 'LBSTCC2020',
    layer: 'task-7-maize-harvest',
  },
  {
    title: 'Task 8: Catch Crop Classification',
    workspace: 'LBSTCC2020',
    layer: 'task-8-catch-crops',
  },
  {
    title: 'Event Markers',
    workspace: 'LBSTCC2020',
    layer: 'parcels-tl',
  },
];

const Tool = () => {
  const {
    actions: { handleFetchData, setParcelLayer, setSearchField },
    state: { legend, parcelId, parcels, parcelLayer, searchField },
  } = React.useContext(AppContext);
  const { isAuthenticated } = useAuth0();
  const [accordion, setAccordion] = React.useState({
    attributes: true,
    legend: true,
    markers: true,
  });

  const handleChange = panel => (e, isExpanded) => {
    setAccordion({ ...accordion, [panel]: isExpanded });
  };

  return (
    <Box>
      {isAuthenticated ? (
        <Box>
          <Box px={2} pb={1}>
            <Select
              fullWidth
              id="select-tasks"
              variant="outlined"
              value={parcelLayer.title}
              onChange={e =>
                setParcelLayer(
                  parcelLayers.filter(
                    layer => layer.title === e.target.value
                  )[0]
                )
              }
            >
              {parcelLayers.map(item => (
                <MenuItem key={item.title} value={item.title}>
                  {item.title}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Search by parcel or farmer ID"
              variant="standard"
              fullWidth
              value={searchField}
              onChange={e => setSearchField(e.target.value)}
              onKeyDown={e => (e.keyCode === 13 ? handleFetchData() : null)}
            />
          </Box>

          {parcelId && parcelLayer.title === 'Event Markers' && (
            <Accordion
              expanded={accordion.markers}
              onChange={handleChange('markers')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: primary.main }} />}
                aria-controls="panel-content"
                id="accordion-header-markers"
              >
                <Typography variant="h6" style={{ padding: 0 }}>
                  Event Markers for Parcel ID: {parcelId}
                </Typography>
              </AccordionSummary>
              <EventMarkers />
            </Accordion>
          )}
          {parcelId && (
            <Accordion
              expanded={accordion.attributes}
              onChange={handleChange('attributes')}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon style={{ color: primary.main }} />}
                aria-controls="panel-content"
                id="accordion-header-attributes"
              >
                <Typography variant="h6" style={{ padding: 0 }}>
                  Attributes for Parcel ID: {parcelId}
                </Typography>
              </AccordionSummary>
              <Box p={2}>
                <CustomTable parcels={parcels} />
              </Box>
            </Accordion>
          )}
        </Box>
      ) : (
        <Login />
      )}

      {parcelLayer.layer === 'task1-tl' && legend && (
        <Accordion
          expanded={accordion.legend}
          onChange={handleChange('legend')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon style={{ color: primary.main }} />}
            aria-controls="panel-content"
            id="accordion-header-legend"
          >
            <Typography variant="h6">Parcels legend:</Typography>
          </AccordionSummary>
          <Box p={2}>
            <Legend legend={legend} />
          </Box>
        </Accordion>
      )}
    </Box>
  );
};

export default Tool;
