export const rasterOptions = [
  {
    title: 'RGB',
    key: 'rgb/S2',
    bands: 'r=B04&g=B03&b=B02',
    stretch: [
      {
        title: 'r_range=',
        range: [0, 3000],
        minMax: [0, 10000],
        key: 'R: B04',
      },
      {
        title: '&g_range=',
        range: [0, 3000],
        minMax: [0, 10000],
        key: 'G: B03',
      },
      {
        title: '&b_range=',
        range: [0, 3000],
        minMax: [0, 10000],
        key: 'B: B02',
      },
    ],
    stretchFactor: 0.0001,
    queryBand: 'B04',
    querySensor: 'S2',
  },
  {
    title: 'Infrared',
    key: 'rgb/S2',
    bands: 'r=B08&g=B03&b=B02',
    stretch: [
      {
        title: 'r_range=',
        range: [0, 10000],
        minMax: [0, 10000],
        key: 'R: B08',
      },
      {
        title: '&g_range=',
        range: [0, 3000],
        minMax: [0, 10000],
        key: 'G: B03',
      },
      {
        title: '&b_range=',
        range: [0, 3000],
        minMax: [0, 10000],
        key: 'B: B02',
      },
    ],
    stretchFactor: 0.0001,
    queryBand: 'B04',
    querySensor: 'S2',
  },
  {
    title: 'NDVI',
    key: 'singleband/S2',
    ndviKey: 'NDVI',
    bands: 'colormap=rdylgn',
    stretch: [
      {
        title: 'stretch_range=',
        range: [0, 10000],
        minMax: [0, 10000],
        key: null,
      },
    ],
    stretchFactor: 0.0001,
    queryBand: 'NDVI',
    querySensor: 'S2',
  },

  // {
  //   title: 'Radar',
  //   key: 'rgb/s1db',
  //   bands: 'r=vh&g=vv&b=vhovervv',
  //   stretch: [
  //     {
  //       title: 'r_range=',
  //       range: [-32800, -11800],
  //       minMax: [-40000, 0],
  //       key: 'R: VH',
  //     },
  //     {
  //       title: '&g_range=',
  //       range: [-20800, -6300],
  //       minMax: [-40000, 0],
  //       key: 'G: VV',
  //     },
  //     {
  //       title: '&b_range=',
  //       range: [1090, 2340],
  //       minMax: [0, 5000],
  //       key: 'B: VV/VH',
  //     },
  //   ],
  //   stretchFactor: 0.001,
  //   queryBand: 'vv',
  //   querySensor: 's1db',
  // },
];
