import React from 'react';
import { useAuth0 } from 'react-auth0-spa';
import { Box, Button, Card, CardContent, Typography } from '@material-ui/core';

const Login = () => {
  const { loginWithPopup } = useAuth0();

  return (
    <Card>
      <Typography
        variant="h6"
        style={{
          backgroundColor: '#F2F5F7',
          padding: '8px 16px',
          borderTop: '1px solid #DBE4E9',
        }}
      >
        Log in
      </Typography>
      <CardContent style={{ padding: '16px' }}>
        <Typography variant="subtitle2" style={{ marginBottom: 8 }}>
          Welcome to the LBST 2020 App.
        </Typography>

        <Typography variant="body2" color="primary">
          Please, log in to access the data and tool capabilities, if you don't
          have an account you need to sign up and verify your account.
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={() => loginWithPopup()}
          >
            Log in / Sign up
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

export default Login;
