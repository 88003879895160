import React from 'react';
import Timeseries from './Timeseries';
import TimeseriesControl from './TimeseriesControl';
import { Box } from '@material-ui/core';
import DHITheme from 'styles/theme';
import { checkboxOptions } from 'config/checkboxOptions';

const { grey } = DHITheme.palette;

const BottomDrawer = () => {
  const [checkboxes, setCheckboxes] = React.useState(checkboxOptions);

  return (
    <Box width={1} height="100%" display="flex">
      <Box flexGrow={1} height="100%" px={2} pb={1} boxSizing="border-box">
        <Timeseries checkboxes={checkboxes} />
      </Box>

      <Box
        width={360}
        height={'100%'}
        style={{ overflow: 'auto', borderLeft: `1px solid ${grey.medium}` }}
        boxSizing="border-box"
      >
        <TimeseriesControl
          checkboxes={checkboxes}
          setCheckboxes={setCheckboxes}
        />
      </Box>
    </Box>
  );
};

export default BottomDrawer;
