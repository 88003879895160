import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import { ThemeProvider, withStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { Auth0Provider } from './react-auth0-spa';
import * as Sentry from '@sentry/browser';

import IEnotification from 'containers/IEnotification';
import config from 'config/auth0.json';
import history from './data/history';

import * as serviceWorker from './serviceWorker';
import './index.css';
import 'typeface-roboto';
import DHITheme from './styles/theme';
import App from './App';

process.env.REACT_APP_ENV === 'production' &&
  Sentry.init({
    dsn:
      'https://9c13c1f4a5204592867d32fee5375be6@o377546.ingest.sentry.io/5285517',
  });

const CustomSnackbarProvider = withStyles({
  variantSuccess: { backgroundColor: '#61C051' },
  variantError: { backgroundColor: '#FD3F75' },
  variantWarning: { backgroundColor: '#FFC20A' },
  variantInfo: { backgroundColor: '#00A4EC' },
})(SnackbarProvider);

const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const isIE = /*@cc_on!@*/ false || !!document.documentMode;

ReactDOM.render(
  <>
    {isIE ? (
      <>
        <IEnotification />
      </>
    ) : (
      <Router history={history}>
        <ThemeProvider theme={DHITheme}>
          <CustomSnackbarProvider>
            <Auth0Provider
              audience={config.audience}
              client_id={config.clientId}
              domain={config.domain}
              onRedirectCallback={onRedirectCallback}
              redirect_uri={window.location.origin}
              scope={config.scope}
            >
              <App />
            </Auth0Provider>
          </CustomSnackbarProvider>
        </ThemeProvider>
      </Router>
    )}
  </>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
