import React from 'react';
import { Box } from '@material-ui/core';
import { format } from 'date-fns';
import Timeline from 'react-visjs-timeline';
import DHITheme from 'styles/theme';
import { AppContext } from 'App';
import 'styles/timeline.css';

const { grey } = DHITheme.palette;

const wrapperStyle = {
  backgroundColor: '#fff',
  border: `2px solid ${grey.medium}`,
  borderRadius: 4,
};

var initiated = false;

const DatePickerTimeline = () => {
  const {
    actions: { setSelectedDate },
    state: { cloudPercentages, dates, selectedDate },
  } = React.useContext(AppContext);
  const [items, setItems] = React.useState();
  const [options, setOptions] = React.useState();

  const timelineRef = React.useRef(null);

  React.useEffect(() => {
    if (selectedDate) {
      const year = selectedDate.substring(0, 4);
      const month = parseInt(selectedDate.substring(4, 6)) - 1;
      const day = selectedDate.substring(6, 8);

      const epochTime = new Date(year, month, day).getTime();

      // add hours to be in the items' start-end range
      const timeZoneDifference = 66400000;
      const localEpoch = epochTime + timeZoneDifference;

      items.forEach(({ end, start }, i) => {
        if (localEpoch >= start && localEpoch <= end) {
          timelineRef.current.$el.setSelection(items[i].id);
        }
      });
    } // eslint-disable-next-line
  }, [selectedDate]);

  React.useEffect(() => {
    if (dates && cloudPercentages) {
      let _items = dates.datasets.map(({ date }, i) => {
        let index = null;

        for (let i = 0; i < cloudPercentages.dates.length; i++) {
          let cloudDate = cloudPercentages.dates[i]
            .substring(0, 10)
            .replace(/[^\w\s]/gi, '');
          if (date === cloudDate) {
            index = i;
            break;
          }
        }

        const cloudValue = cloudPercentages.variables[0].values[index];
        const opacity = cloudValue ? 0.2 + cloudValue * 0.01 * 0.8 : 0.5;

        const year = date.substring(0, 4);
        const day = date.substring(6, 8);
        // new Monts are zero based
        const month = parseInt(date.substring(4, 6)) - 1;
        const _date = new Date(year, month, day);

        return {
          id: i,
          start: _date.setHours(_date.getHours() + 2),
          end: _date.setHours(_date.getHours() + 20),
          style: `background-color: rgba(0, 164, 236, ${opacity})`,
          title: `${format(_date, 'MMM dd')}: ${100 - cloudValue}% clouds`,
          tooltip: { overflow: 'flip' },
        };
      });

      setOptions({
        margin: {
          item: {
            horizontal: 0,
          },
          axis: 0,
        },
        maxHeight: 92,
        minHeight: 92,
        timeAxis: {
          scale: 'day',
          step: 1,
        },
        zoomMax: 18368000000,
      });
      setItems(_items);
    }
  }, [dates, cloudPercentages]);

  // hacky-di-hack: focus timeline on current date after first load
  React.useEffect(() => {
    if (items && !initiated && timelineRef) {
      let viewDate = new Date();
      viewDate.setMonth(viewDate.getMonth() - 3);
      setTimeout(function() {
        timelineRef.current.$el.setWindow(viewDate, new Date());
        const dateMs = items[items.length - 1].end;
        const lastDate = format(new Date(dateMs), 'yyyyMMdd');
        setSelectedDate(lastDate);
        initiated = true;
      }, 1000);
    } // eslint-disable-next-line
  }, [items]);

  return (
    <Box ml={2}>
      {items && (
        <Box style={wrapperStyle}>
          <Timeline
            ref={timelineRef}
            options={options}
            items={items}
            clickHandler={e =>
              e.item && setSelectedDate(format(e.time, 'yyyyMMdd'))
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default DatePickerTimeline;
