import { makeStyles } from '@material-ui/core/styles';
import DHITheme from 'styles/theme';
const { primary, secondary } = DHITheme.palette;

export const useStyles = makeStyles({
  geolocate: {
    left: 0,
    margin: 10,
    position: 'absolute',
    top: 40,
  },
  tooltip: {
    color: '#fff',
    fontSize: 18,
    fontWeight: 'bold',
    position: 'absolute',
    margin: '8px',
    padding: '4px',
    background: 'rgba(0, 0, 0, 0.8)',
    maxWidth: '300px',
    zIndex: 9,
    pointerEvents: 'none',
  },
  navigationControl: {
    position: 'absolute',
    top: 36,
    left: 0,
    padding: '10px',
  },
  scaleControl: {
    position: 'absolute',
    bottom: 36,
    left: 0,
    padding: '10px',
  },
  panel: {
    position: 'absolute',
    top: 0,
    right: 0,
    maxWidth: '400px',
    background: '#fff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
    padding: '12px',
    margin: '20px',
    fontSize: '13px',
    lineHeight: 2,
    color: '#6b6b76',
    textTransform: 'uppercase',
    outline: 'none',
  },
});

export const tilesLayer = {
  id: 'tiles-layer',
  type: 'fill',
  paint: {
    'fill-color': 'transparent',
    'fill-outline-color': `${primary.main}`,
  },
};

export const tilesLineLayer = {
  id: 'tiles-lines',
  type: 'line',
  paint: {
    'line-color': `${primary.main}`,
    'line-width': 2,
  },
};

export const parcelsLayer = {
  id: 'parcels-geojson',
  type: 'line',
  paint: {
    // 'fill-color': 'transparent',
    // 'fill-outline-color': '#0b4566',
    'line-color': '#00A4EC',
    'line-width': 5,
  },
};

export const coverageLayer = {
  id: 'coverage-layer',
  type: 'fill',
  paint: {
    'fill-opacity': 0.8,
    'fill-color': {
      property: 'log_count',
      stops: [
        [0, '#3288bd'],
        [1, '#66c2a5'],
        [2, '#abdda4'],
        [3, '#e6f598'],
        [4, '#ffffbf'],
        [5, '#fee08b'],
        [6, '#fdae61'],
        [7, '#f46d43'],
        [8, '#d53e4f'],
      ],
    },
  },
};

export const locationsLayer = {
  id: 'locations-layer',
  type: 'circle',
  paint: {
    'circle-color': '#0b4566',
    'circle-opacity': 0.8,
    'circle-radius': ['case', ['boolean', ['get', 'isHovering'], false], 9, 6],
  },
};

export const highlightLayer = {
  id: 'highlight',
  type: 'circle',
  paint: {
    'circle-color': `${primary.main}`,
    'circle-stroke-color': `${secondary.main}`,
    'circle-stroke-width': 2,
    'circle-opacity': 1,
    'circle-radius': [
      'case',
      ['boolean', ['get', 'isHovering'], false],
      15,
      10,
    ],
  },
};
