import React, { useState } from 'react';
import { Layer, Source } from 'react-map-gl';
import { AppContext } from 'App';

const terracotta_url = process.env.REACT_APP_TERRACOTTA;

const TerracottaLayer = ({ viewport }) => {
  const {
    state: { activeTile, mapbox, rasterType, selectedDate },
  } = React.useContext(AppContext);

  const [url, setUrl] = useState(false);

  React.useEffect(() => {
    mapbox && selectedDate && computeUrl();
    // eslint-disable-next-line
  }, [
    selectedDate,
    // eslint-disable-next-line
    rasterType.stretch[0].range,
    // eslint-disable-next-line
    rasterType.stretch[1] && rasterType.stretch[1].range,
    // eslint-disable-next-line
    rasterType.stretch[2] && rasterType.stretch[2].range,
  ]);

  React.useEffect(() => {
    viewport.zoom > 10 &&
      url &&
      mapbox.moveLayer('tiles-wms-layer', 'parcels-wms-layer');
    // eslint-disable-next-line
  }, [url]);

  const computeUrl = () => {
    try {
      const { key, bands, stretch } = rasterType;
      const stretchRanges = stretch.map(
        ({ title, range }) => `${title}[${range[0]},${range[1]}]`
      );
      const ndvikey = rasterType.ndviKey ? `${rasterType.ndviKey}/` : '';
      const _url = `${terracotta_url}/${key}/${activeTile}/${selectedDate}/${ndvikey}{z}/{x}/{y}.png?${bands}&${stretchRanges.join(
        ''
      )}`;

      if (url) {
        mapbox.getSource('tiles-wms').tiles = [_url];
        mapbox.style.sourceCaches['tiles-wms'].clearTiles();
        mapbox.style.sourceCaches['tiles-wms'].update(mapbox.transform);
        mapbox.triggerRepaint();
        viewport.zoom > 10 &&
          mapbox.moveLayer('tiles-wms-layer', 'parcels-wms-layer');
        // mapbox.moveLayer('parcels-wms-layer', 'parcels-geojson');
      } else {
        setUrl({
          tiles: [_url],
          tileSize: 256,
          attribution:
            '<a href="https://sentinel.esa.int/web/sentinel/home" target="blank">' +
            'Contains modified Copernicus Sentinel data ' +
            new Date().getFullYear() +
            ' ' +
            '</a>',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    url && (
      <Source id="tiles-wms" type="raster" {...url}>
        <Layer id="tiles-wms-layer" type="raster" source="tiles-wms" />
      </Source>
    )
  );
};

export default TerracottaLayer;
