import React from 'react';
import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import { infoProducts } from './defaultProducts';
import { makeStyles } from '@material-ui/core/styles';

import DHITheme from 'styles/theme';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={2}>{children}</Box>}
    </Typography>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  tabs: {
    backgroundColor: '#fff',
    borderBottom: DHITheme.border.bottom,
    color: DHITheme.palette.primary.main,
    minHeight: 40,
  },
  tab: {
    fontSize: 10,
    minHeight: 40,
    minWidth: '33.3%',
    padding: 0,
    textTransform: 'capitalize',
    '&:hover': {
      fontWeight: 700,
    },
  },
  appbar: {
    boxShadow: 'none',
  },
}));

export default function SimpleTabs() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6">Product information</Typography>
      <AppBar position="static" className={classes.appbar}>
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.tabs}
          TabIndicatorProps={{
            style: {
              backgroundColor: DHITheme.palette.primary.main,
            },
          }}
        >
          {infoProducts.map(({ title }, i) => (
            <Tab
              className={classes.tab}
              key={title}
              label={title}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </AppBar>
      {infoProducts.map(({ description, title }, i) => (
        <TabPanel value={value} index={i} key={title}>
          <Typography variant="subtitle1" style={{ padding: '8px 0px' }}>
            {title}
          </Typography>
          {description}
        </TabPanel>
      ))}
    </div>
  );
}
