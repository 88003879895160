import React from 'react';
import { Box } from '@material-ui/core';
import DHITheme from 'styles/theme';
const { grey } = DHITheme.palette;

const LineIcon = ({ checked, color }) => {
  return (
    <Box pr={0.5} pt={1.33}>
      <Box
        width={12}
        height={3}
        style={{
          borderRadius: 4,
          backgroundColor: checked ? color : `${grey.dark}`,
        }}
      ></Box>
    </Box>
  );
};

export default LineIcon;
