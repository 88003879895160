import React from 'react';
import { compose } from 'recompose';
import {
  CircularProgress,
  LinearProgress,
  withStyles,
} from '@material-ui/core';
import styles from './styles';

const enhancer = compose(withStyles(styles, { withTheme: true }));

const Loader = ({ classes, isLoading, isMajorLoading }) => {
  if (!isLoading) return null;
  return (
    <>
      {isMajorLoading ? (
        <div className={classes.majorLoading}>
          <CircularProgress color="inherit" size={50} thickness={5} />
        </div>
      ) : (
        <div className={classes.minorLoading}>
          <LinearProgress color="primary" />
        </div>
      )}
    </>
  );
};

export default enhancer(Loader);
