import React from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import DatePickerTimeline from './partials/DatePickerTimeline';
import DHITheme from 'styles/theme';
import SliderNDVI from './partials/SliderNDVI';
import SliderMultiband from './partials/SliderMultiband';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { ReactComponent as AdjustIcon } from '@mike/mike-shared-frontend/media/icons/Adjust.svg';
import { AppContext } from 'App';

const { grey, primary, secondary } = DHITheme.palette;

const buttonStyle = makeStyles({
  button: {
    width: 64,
    height: 32,
    backgroundColor: '#fff',
    border: `2px solid ${grey.dark}`,
    borderRadius: 4,
    color: `${primary.main}`,
    padding: '0px 8px',
    margin: '0px 2px',
    '&:hover': {
      backgroundColor: '#EBF8FE',
    },
  },
  buttonSelect: {
    backgroundColor: '#EBF8FE',
    border: `1px solid ${secondary.main}`,
  },
  buttonSlider: {
    height: 32,
    width: 32,
    minWidth: 32,
    borderRadius: 16,
    padding: 0,
  },
});

const MapControls = () => {
  const {
    actions: { setRasterType },
    state: { rasterOptions, rasterType },
  } = React.useContext(AppContext);

  const [showSliders, setSliders] = React.useState(false);
  const classes = buttonStyle();

  return (
    <Box
      height={'100%'}
      display="flex"
      alignItems="flex-end"
      justifyContent="flex-end"
    >
      <Box flexGrow={1} alignItems="flex-end">
        <DatePickerTimeline />
      </Box>

      <Box
        width={360}
        height={'100%'}
        display="flex"
        flexWrap="wrap"
        alignItems="flex-end"
        justifyContent="flex-end"
        mr={1}
      >
        <Box>
          {showSliders && rasterType.title === 'NDVI' && <SliderNDVI />}
          {showSliders && rasterType.title !== 'NDVI' && <SliderMultiband />}
        </Box>
        <Box>
          {rasterOptions &&
            rasterOptions.map(raster => (
              <Button
                variant="outlined"
                className={classnames({
                  [classes.button]: true,
                  [classes.buttonSelect]: raster.title === rasterType.title,
                })}
                onClick={() => setRasterType(_.cloneDeep(raster))}
                key={raster.title}
              >
                <Typography variant="subtitle1">{raster.title}</Typography>
              </Button>
            ))}
          <Button
            variant="outlined"
            className={classnames({
              [classes.button]: true,
              [classes.buttonSlider]: true,
              [classes.buttonSelect]: showSliders,
            })}
            width={32}
            onClick={() => setSliders(!showSliders)}
          >
            <AdjustIcon />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default MapControls;
