import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';
import DHITheme from 'styles/theme';

const { grey } = DHITheme.palette;

const StyledTableCell = withStyles(theme => ({
  body: {
    fontSize: 11,
    color: theme.palette.primary.main,
  },
  head: {
    backgroundColor: theme.palette.grey.medium,
    color: theme.palette.primary.main,
  },
  root: {
    padding: '5px 10px',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey.light,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 250,
    maxWidth: 350,
  },
});

const CustomTable = ({ parcels }) => {
  const classes = useStyles();

  return (
    parcels && (
      <Box style={{ border: `1px solid ${grey.medium}`, borderRadius: 4 }}>
        <TableContainer className={classes.table} elevation={10}>
          <Table size="medium" aria-label="customized table">
            <TableBody>
              {Object.entries(parcels.features[0].properties).map(
                ([key, value]) =>
                  key !== 'bbox' && (
                    <StyledTableRow key={key}>
                      <StyledTableCell component="th" scope="row">
                        {key}
                      </StyledTableCell>
                      <StyledTableCell align="right">{value}</StyledTableCell>
                    </StyledTableRow>
                  )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  );
};

export default CustomTable;
