import React from 'react';
import clsx from 'clsx';
import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import DHITheme from 'styles/theme';
import { AppContext } from 'App';

const { grey } = DHITheme.palette;

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    padding: 0,
  },
  icon: {
    borderRadius: '50%',
    width: 14,
    height: 14,
    boxShadow:
      'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#0B4566',
    backgroundImage:
      'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 14,
      height: 14,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  table: {
    minWidth: 250,
    maxWidth: 350,
  },
});

const StyledRadio = props => {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      style={{ width: 16, height: 16 }}
      {...props}
    />
  );
};

const StyledTableCell = withStyles(theme => ({
  body: {
    fontSize: 11,
    color: theme.palette.primary.main,
    whiteSpace: 'nowrap',
  },
  head: {
    backgroundColor: theme.palette.grey.medium,
    color: theme.palette.primary.main,
    lineHeight: '1rem',
  },
  root: {
    padding: '5px 10px',
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey.light,
    },
  },
}))(TableRow);

const EventMarkers = () => {
  const classes = useStyles();

  const {
    actions: { setActiveMarker },
    state: { activeMarker, markers },
  } = React.useContext(AppContext);

  console.log(markers);

  return (
    markers && (
      <Box p={2}>
        <RadioGroup
          name="markers-radios"
          onChange={e => setActiveMarker(e.target.value)}
          value={activeMarker}
        >
          {markers.map(({ marker_name, events }) => (
            <FormControlLabel
              key={marker_name}
              value={marker_name}
              label={
                events.length === 0 ? `${marker_name} (no events)` : marker_name
              }
              disabled={events.length === 0}
              control={<StyledRadio />}
            />
          ))}
        </RadioGroup>

        {markers.map(({ events, marker_name }) =>
          marker_name === activeMarker && events.length > 0 ? (
            <Box
              key={marker_name}
              style={{
                border: `1px solid ${grey.medium}`,
                borderRadius: 4,
                marginTop: 8,
              }}
            >
              <TableContainer className={classes.table} elevation={10}>
                <Table size="medium" aria-label="events-table">
                  <TableHead>
                    <TableRow>
                      {Object.keys(events[0]).map(key => (
                        <StyledTableCell key={key}>{key}</StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {events.map((event, index) => (
                      <StyledTableRow key={`${index}.${event.signal}`}>
                        {Object.entries(event).map(([key, value]) => (
                          <StyledTableCell
                            key={key}
                            role="cell"
                            scope="row"
                            align="right"
                          >
                            {value}
                          </StyledTableCell>
                        ))}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : null
        )}
      </Box>
    )
  );
};

export default EventMarkers;
