import React from 'react';
import { Box } from '@material-ui/core';
import DHITheme from 'styles/theme';
const { grey } = DHITheme.palette;

const MarkerIcon = ({ checked, color }) => {
  return (
    <Box pr={1} pt={1}>
      <Box
        width={8}
        height={8}
        style={{
          borderRadius: 4,
          backgroundColor: checked ? color : `${grey.dark}`,
        }}
      ></Box>
    </Box>
  );
};

export default MarkerIcon;
