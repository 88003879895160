import React from 'react';
import { Box, Link, Typography } from '@material-ui/core';

const About = () => {
  return (
    <Box>
      <Typography variant="subtitle1">About this site</Typography>
      <Typography variant="body2">
        This site provide easy access to water quality information in Danish
        waters. The information is based on input data from the Copernicus
        Sentinel-3 satellite program processed using DHI GRAS in-house
        processing environment. It facilitates a quick spatial overview of the
        current and past conditions with daily updates in near-real-time.
      </Typography>
      <Typography variant="subtitle1">How to use it</Typography>
      <Typography variant="body2">
        You can chose between two water quality parameters: Chlorophyll-a and
        Turbidity (each shown as daily images or as 10-day running mean
        aggregations). The colour scale is adjustable so you can create custom
        visualizations. Access to the underlying data can be arranged by special
        agreement with us.
      </Typography>

      <Typography variant="subtitle1">About DHI</Typography>
      <Typography variant="body2">
        DHI are the first people you should call when you have a tough challenge
        to solve in a water environment – be it a river, a reservoir, an ocean,
        a coastline, or an aquifer. Our knowledge of water environments is
        second-to-none. It represents 50 years of dedicated research and
        real-life experience from more than 140 countries. We strive to make
        this knowledge globally accessible to clients and partners by channeling
        it through our local teams and unique software. Our world is water. So
        whether you need to save water, share it fairly, improve its quality,
        quantify its impact or manage its flow, we can help. Our knowledge,
        combined with our team's expertise and the power of our technology,
        holds the key to unlocking the right solution. Read more on
        <Link href="https://www.dhigroup.com/"> dhigroup.com</Link>
      </Typography>
    </Box>
  );
};

export default About;
