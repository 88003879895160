import React from 'react';
import Plot from 'components/ReactPlotly/Plot';
import { format, parseISO } from 'date-fns';
import { AppContext } from 'App';
import DHITheme from 'styles/theme';
import { mapping } from './timeseriesDataMapping';

const { grey, primary } = DHITheme.palette;

const Timeseries = ({ checkboxes }) => {
  const {
    actions: { setSelectedDate },
    state: { dates, timeseries },
  } = React.useContext(AppContext);
  const [data, setData] = React.useState(false);

  const setDate = e => {
    const date = e.points[0].x;
    const formatDate = format(parseISO(date), 'yyyyMMdd');
    dates.datasets.forEach(
      ({ date }) => date === formatDate && setSelectedDate(formatDate)
    );
  };

  React.useEffect(() => {
    try {
      if (timeseries) {
        const statsChecked = checkboxes.map(group =>
          group.stats.filter(stat => stat.checked).map(stat => stat.name)
        );

        const timeseriesChecked = timeseries
          .map(
            ({ dates, variables }, i) =>
              variables.filter(({ name }) => statsChecked[i].includes(name))
                .length > 0 && {
                sensor: i,
                dates,
                variables: variables.filter(({ name }) =>
                  statsChecked[i].includes(name)
                ),
              }
          )
          .filter(item => item);

        const data = timeseriesChecked
          .map(({ dates, sensor, variables }) =>
            variables.map(variable => ({
              name: mapping[sensor][variable.name].name,
              x: dates,
              y: variable.values,
              marker: {
                color: mapping[sensor][variable.name].color,
              },
              yaxis: mapping[sensor][variable.name].axis,
              type: 'scatter',
              mode: [0, 1].includes(sensor) ? 'markers' : 'lines',
            }))
          )
          .flat();
        setData(data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [checkboxes, setData, timeseries]);

  return (
    data && (
      <Plot
        data={data}
        config={{ displayModeBar: false }}
        layout={{
          autosize: true,
          showlegend: false,
          hovermode: 'closest',
          font: {
            size: 9,
            color: `${primary.main}`,
          },
          margin: {
            l: 20,
            r: 20,
            b: 40,
            t: 20,
          },
          xaxis: {
            showgrid: false,
            zerolinecolor: `${grey.dark}`,
            domain: [0, 1],
          },
          yaxis: {
            showgrid: true,
            zerolinecolor: `${grey.dark}`,
          },
          yaxis2: {
            overlaying: 'y',
            position: 1,
            range: [0, -30],
            showgrid: false,
            tickfont: { color: '#ffb1c8' },
          },
          yaxis3: {
            overlaying: 'y',
            position: 0.95,
            showgrid: false,
            tickfont: { color: '#CF6766' },
          },
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler
        onClick={setDate}
      />
    )
  );
};

export default Timeseries;
