import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import Nouislider from 'nouislider-react';
import DHITheme from 'styles/theme';
import { AppContext } from 'App';

import 'nouislider/distribute/nouislider.css';
import 'styles/slider.css';

const { grey } = DHITheme.palette;

const wrapperStyle = {
  backgroundColor: '#fff',
  border: `2px solid ${grey.medium}`,
  borderRadius: 4,
};

const legendStyle = { fontSize: 10 };
const gridStyle = { height: 20 };

const SliderMultiband = () => {
  const {
    actions: { setRasterType },
    state: { rasterType },
  } = React.useContext(AppContext);

  //   const [sliderValues, setSliderValues] = React.useState();

  //   React.useEffect(() => {
  //     setSliderValues(rasterType.stretch.map(({ range }) => range));
  //   }, []);

  //   const setValueRange = (i, val) => {
  //     let arr = sliderValues;
  //     arr[i] = [parseInt(val[0]), parseInt(val[1])];
  //     if (
  //       Math.abs(arr[i][0] - sliderValues[i][0]) < 100 &&
  //       Math.abs(arr[i][1] - sliderValues[i][1]) < 100
  //     )
  //       setSliderValues([...arr]);
  //   };

  const handleDataRange = (i, val) => {
    let obj = rasterType;
    obj.stretch[i].range = [parseInt(val[0]), parseInt(val[1])];

    setRasterType({ ...obj });
  };

  return (
    <Box px={1} pt={1} width={286} mb={1} style={wrapperStyle}>
      <Grid container>
        {rasterType &&
          rasterType.stretch &&
          rasterType.stretch.map(({ key, minMax, range }, i) => (
            <React.Fragment key={i}>
              <Grid item xs={2} style={gridStyle}>
                <Typography variant="body2" style={legendStyle}>
                  {key}
                </Typography>
              </Grid>
              <Grid item xs={1} style={gridStyle}>
                <Typography variant="body2" style={legendStyle}>
                  {(range[0] * rasterType.stretchFactor).toFixed(2)}
                </Typography>
              </Grid>
              <Grid item xs={8} style={gridStyle}>
                <Box px={1} pt={0.5}>
                  <Nouislider
                    start={[range[0], range[1]]}
                    connect={[false, true, false]}
                    margin={100}
                    range={{ min: minMax[0], max: minMax[1] }}
                    onChange={val => handleDataRange(i, val)}
                    // onUpdate={val => setValueRange(i, val)}
                  />
                </Box>
              </Grid>
              <Grid item xs={1} style={gridStyle}>
                <Typography variant="body2" style={legendStyle}>
                  {(range[1] * rasterType.stretchFactor).toFixed(2)}
                </Typography>
              </Grid>
            </React.Fragment>
          ))}
      </Grid>
    </Box>
  );
};

export default SliderMultiband;
