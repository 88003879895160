import React from 'react';
import { Box } from '@material-ui/core';
import _ from 'lodash';
import Nouislider from 'nouislider-react';
import Legend from './Legend';
import NDVILegend from 'assets/images/ndvi-colorbar.png';
import DHITheme from 'styles/theme';
import { AppContext } from 'App';

import 'nouislider/distribute/nouislider.css';
import 'styles/slider.css';

const { grey } = DHITheme.palette;

const wrapperStyle = {
  backgroundColor: '#fff',
  border: `2px solid ${grey.medium}`,
  borderRadius: 4,
};

const SliderNDVI = () => {
  const {
    actions: { setRasterType },
    state: { rasterType },
  } = React.useContext(AppContext);

  const { range, minMax } = rasterType.stretch[0];

  const handleDataRange = val => {
    let obj = _.cloneDeep(rasterType);
    obj.stretch[0].range = [parseInt(val[0]), parseInt(val[1])];
    setRasterType(obj);
  };

  const [legendRange, setLegendRange] = React.useState(range);
  const length = 6;

  return (
    <Box px={2} pt={2} pb={1} width={270} mb={1} style={wrapperStyle}>
      <Nouislider
        start={range}
        connect={[false, true, false]}
        margin={length}
        range={{ min: minMax[0], max: minMax[1] }}
        onChange={val => handleDataRange(val)}
        onUpdate={val => setLegendRange([parseInt(val[0]), parseInt(val[1])])}
      />
      <Box pt={1} />
      <Legend src={NDVILegend} length={length} range={legendRange} />
    </Box>
  );
};

export default SliderNDVI;
