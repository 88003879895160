import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { titleStyle } from 'styles/global';

export default function Title() {
  const classes = titleStyle();
  return (
    <Box className={classes.container}>
      <Grid container justify={'space-between'}>
        <Grid item xs={8}>
          <Typography className={classes.titleMain}>LBST 2020</Typography>
        </Grid>
        <Grid item xa={4}>
          <img
            src="images/DHI_Logo_Blue.png"
            alt={'DHI Logo'}
            style={{ width: 36 }}
          />
        </Grid>
      </Grid>
      <Typography className={classes.subTitle}>
        Landbrugsstyrelsen Danmark
      </Typography>
    </Box>
  );
}
