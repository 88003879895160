import React from 'react';
import ReactMapGL, {
  Layer,
  NavigationControl,
  ScaleControl,
  Source,
} from 'react-map-gl';
import { useAuth0 } from 'react-auth0-spa';
import { Box, Typography } from '@material-ui/core';
import ParcelsLayer from './partials/ParcelsLayer';
import TerracottaLayer from './partials/TerracottaLayer';

import {
  useStyles,
  tilesLayer,
  tilesLineLayer,
  parcelsLayer,
} from './styles.js';
import { mapStyles } from 'config/config.json';
import 'mapbox-gl/dist/mapbox-gl.css';

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
const tiles = require('assets/geojsons/sen2_tiles_dk.json');
const featuresList = ['tiles-layer', 'parcels-layer'];

const Map = ({
  handleFindParcels,
  handleViewport,
  parcels,
  showTiles,
  setActiveTile,
  setMapbox,
  setViewport,
  viewport,
}) => {
  const classes = useStyles();
  const { isAuthenticated } = useAuth0();
  const [hoverFeature, setHoverFeature] = React.useState(false);
  const [tooltip, setTooltip] = React.useState(false);

  // React.useEffect(() => {
  //   try {
  //     if (parcels) {
  //       handleViewport({
  //         ...viewport,
  //         latitude: (parcels.bbox[1] + parcels.bbox[3]) / 2,
  //         longitude: (parcels.bbox[0] + parcels.bbox[2]) / 2,
  //         zoom: 10.5,
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //   }
  //   // eslint-disable-next-line
  // }, [parcels]);

  const onMapClick = event => {
    try {
      const {
        features,
        lngLat: [lon, lat],
      } = event;

      const clickedFeature =
        features && features.find(f => featuresList.includes(f.layer.id));

      if (clickedFeature) {
        if (clickedFeature.layer.id === 'tiles-layer') {
          const center = JSON.parse(clickedFeature.properties.center);
          handleViewport({
            latitude: center[1],
            longitude: center[0],
            zoom: 8,
          });
          setActiveTile(clickedFeature.properties.name);
        }
      }
      if (viewport.zoom > 10) handleFindParcels({ lat, lon });
    } catch (error) {
      console.error(error);
    }
  };

  const onMapHover = event => {
    try {
      const {
        features,
        srcEvent: { offsetX, offsetY },
      } = event;

      const hoveredFeature =
        features && features.find(f => ['tiles-layer'].includes(f.layer.id));

      if (hoveredFeature) {
        setHoverFeature(true);
        setTooltip({
          properties: hoveredFeature.properties,
          x: offsetX,
          y: offsetY,
        });
      } else {
        setHoverFeature(false);
        setTooltip(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const renderTooltip = () =>
    tooltip && (
      <Box
        style={{
          left: tooltip.x,
          top: tooltip.y,
        }}
        className={classes.tooltip}
      >
        <Box>
          <Typography variant="body1">{tooltip.properties.name}</Typography>
          {/* {Object.entries(tooltip.properties).map(([key, value]) => (
            <Typography key={key} variant="body1">
              {key}: {value}
            </Typography>
          ))} */}
        </Box>
      </Box>
    );

  return (
    <ReactMapGL
      {...viewport}
      mapStyle={mapStyles.light}
      width="100%"
      height="100%"
      getCursor={() => hoverFeature && 'pointer'}
      mapboxApiAccessToken={accessToken}
      onViewportChange={setViewport}
      onClick={onMapClick}
      onHover={onMapHover}
      ref={ref => ref && setMapbox(ref.getMap())}
    >
      <div className={classes.navigationControl}>
        <NavigationControl />
      </div>
      <div className={classes.scaleControl}>
        <ScaleControl />
      </div>

      {isAuthenticated && (
        <>
          {viewport.zoom < 8 && showTiles && (
            <Source type="geojson" data={tiles}>
              <Layer {...tilesLayer} />
              <Layer {...tilesLineLayer} />
            </Source>
          )}

          <TerracottaLayer viewport={viewport} />

          {viewport.zoom > 10 && <ParcelsLayer parcels={parcels} />}

          {parcels && (
            <Source type="geojson" data={parcels}>
              <Layer {...parcelsLayer} />
            </Source>
          )}

          {renderTooltip()}
        </>
      )}
    </ReactMapGL>
  );
};

export default Map;
