import React from 'react';
import Tool from './Tool';
import Support from './Support';
import Info from './Info';
import Title from './Title/Title';

import { AppContext } from 'App';
import { Paper } from '@material-ui/core';

import 'styles/scrollbar.css';

const Sidebar = () => {
  const {
    state: { activeTab },
  } = React.useContext(AppContext);

  return (
    <Paper
      style={{
        overflowX: 'hidden',
        overflowY: 'auto',
        height: '100%',
      }}
    >
      <Title />
      {activeTab === 0 && <Info />}
      {activeTab === 1 && <Tool />}
      {activeTab === 2 && <Support />}
    </Paper>
  );
};

export default Sidebar;
