import axios from 'axios';

const agristats_url = process.env.REACT_APP_AGRISTATS_API;
const geoserver_url = process.env.REACT_APP_GEOSERVER;
const terracotta_url = process.env.REACT_APP_TERRACOTTA;

export const fetchData = async ({ layer, query, workspace }) => {
  try {
    const endpoint = `${geoserver_url}/${workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${workspace}:${layer}&outputFormat=application%2Fjson&cql_filter=${query}`;
    const response = await axios.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response) throw Error(`Request rejected`);
    const { data } = response || {};

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchEvents = async ({ parcelId }) => {
  try {
    const endpoint = `${agristats_url}/query-events?parcel=${parcelId}`;
    const response = await axios.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log(response);

    if (!response) throw Error(`Request rejected`);
    const { data } = response || {};

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTimelineDates = async ({ band, sensor, tile }) => {
  if (!sensor || !tile || !band) return;
  try {
    const endpoint = `${terracotta_url}/datasets?limit=10000&tile=${tile}&band=${band}&sensor=${sensor}`;
    const response = await axios.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response) throw Error(`Request rejected`);
    const { data } = response || {};

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchCloudPercentages = async ({ sensor, stat, tile }) => {
  if (!sensor || !tile || !stat) return;
  try {
    const endpoint = `${agristats_url}/scene-stats?sensor=${sensor}&tile=${tile}&names=${stat}`;
    const response = await axios.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response) throw Error(`Request rejected`);
    const { data } = response || {};

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTimeseries = async ({ names, parcelId, sensor }) => {
  try {
    const endpoint = `${agristats_url}/parcel-stats?sensor=${sensor}&parcel=${parcelId}&names=${names}`;
    const response = await axios.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response) throw Error(`Request rejected`);
    const { data } = response || {};

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchParcels = async ({ lat, lon, layer, workspace }) => {
  try {
    const endpoint = `${geoserver_url}/${workspace}/ows?service=WFS&version=1.0.0&request=GetFeature&typeName=${workspace}:${layer}&outputFormat=application/json&BBOX=${lon},${lat},${lon},${lat}`;
    const response = await axios.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response) throw Error(`Request rejected`);
    const { data } = response || {};

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchTile = async ({ parcelId }) => {
  try {
    const endpoint = `${agristats_url}/tile-from-parcel?parcel=${parcelId}`;
    const response = await axios.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response) throw Error(`Request rejected`);
    const { data } = response || {};

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const fetchLegend = async ({ layer, workspace }) => {
  try {
    const endpoint = `${geoserver_url}/wms?service=wms&version=1.1.0&request=GetLegendGraphic&layer=${workspace}:${layer}&format=application/json`;
    const response = await axios.get(endpoint, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response) throw Error(`Request rejected`);
    const { data } = response || {};

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
