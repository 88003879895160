import React from 'react';
import Nouislider from 'nouislider-react';
import { Box, Typography, Grid } from '@material-ui/core';
import { AppContext } from 'App';

import 'nouislider/distribute/nouislider.css';
import 'styles/slider.css';

const Legend = ({ legend }) => {
  const {
    actions: { setClassificationOpacity },
    state: { classificationOpacity },
  } = React.useContext(AppContext);

  const handleDataRange = val => {
    setClassificationOpacity(parseFloat(val));
  };

  return (
    <>
      <Box pb={1}>
        <Grid container>
          <Grid item xs={3}>
            <Typography variant="body2">Set Opacity</Typography>
          </Grid>
          <Grid item xs={7} style={{ paddingTop: 5 }}>
            <Nouislider
              start={classificationOpacity}
              connect={[true, false]}
              range={{ min: 0, max: 1 }}
              onChange={val => handleDataRange(val)}
              // onUpdate={val => setLegendRange([parseInt(val[0]), parseInt(val[1])])}
            />
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" style={{ textAlign: 'right' }}>
              {parseFloat(classificationOpacity).toFixed(2)}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-end"
        justifyContent="flex-end"
        mr={1}
      >
        {legend &&
          legend.Legend[0].rules.map(({ name, symbolizers }) => (
            <Grid key={name} container item xs={6}>
              <Box
                display="flex"
                alignItems="flex-start"
                style={{ cursor: 'pointer' }}
              >
                <Box
                  width={10}
                  height={10}
                  mr={1}
                  style={{
                    backgroundColor: symbolizers[0].Polygon.fill,
                  }}
                />
                <Typography variant="body2" style={{ fontSize: 12 }}>
                  {name}
                </Typography>
              </Box>
            </Grid>
          ))}
      </Box>
    </>
  );
};

export default Legend;
