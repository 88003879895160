import React from 'react';
import * as Sentry from '@sentry/browser';
import { useSnackbar } from 'notistack';
import { Box } from '@material-ui/core';
import { FlyToInterpolator } from 'react-map-gl';
import { easeCubicInOut } from 'd3-ease';

import BottomDrawer from 'containers/BottomDrawer';
import Loader from 'components/Loader';
import Map from 'containers/Map';
import MapControls from 'components/MapControls';
import Sidebar from 'containers/Sidebar';
// import SidebarControl from 'containers/SidebarControl';

import DHITheme from 'styles/theme';
import {
  fetchData,
  fetchEvents,
  fetchCloudPercentages,
  fetchParcels,
  fetchTile,
  fetchTimelineDates,
  fetchTimeseries,
} from 'data';
import { rasterOptions } from 'data/rasterSettings';
import { checkboxOptions } from 'config/checkboxOptions';

const { grey } = DHITheme.palette;

export const AppContext = React.createContext();

const App = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [activeMarker, setActiveMarker] = React.useState(false);
  const [activeTab, setTab] = React.useState(1);
  const [activeTile, setActiveTile] = React.useState(false);
  const [cloudPercentages, setCloudPercentages] = React.useState();
  const [dates, setDates] = React.useState();
  const [legend, setLegend] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [mapbox, setMapbox] = React.useState(null);
  const [markers, setMarkers] = React.useState(false);
  const [openControls, setOpenControls] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(true);
  const [openBottomDrawer, setOpenBottomDrawer] = React.useState(false);
  const [parcelId, setParcelId] = React.useState(false);
  const [parcelLayer, setParcelLayer] = React.useState({
    title: 'Overview',
    workspace: 'LBSTCC2020',
    layer: 'parcels-tl',
  });
  const [parcels, setParcels] = React.useState(false);
  const [classificationOpacity, setClassificationOpacity] = React.useState(1);
  const [rasterType, setRasterType] = React.useState(rasterOptions[0]);
  const [searchField, setSearchField] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState(false);
  const [showTiles, setShowTiles] = React.useState(true);
  const [timeseries, setTimeseries] = React.useState(false);
  const [viewport, setViewport] = React.useState({
    longitude: 11,
    latitude: 56.5,
    zoom: 6,
    minZoom: 1,
    pitch: 0,
    bearing: 0,
  });

  const handleViewport = ({ latitude, longitude, zoom }) =>
    setViewport({
      ...viewport,
      latitude,
      longitude,
      zoom,
      transitionDuration: 1750,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: easeCubicInOut,
    });

  // eslint-disable-next-line
  const handleFetchEvents = React.useCallback(async () => {
    try {
      setLoading(true);

      const eventsResponse = await fetchEvents({ parcelId });
      if (!eventsResponse) throw Error(`Request rejected`);

      setMarkers(eventsResponse.data);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  });

  // eslint-disable-next-line
  const handleFetchTimeseries = React.useCallback(async () => {
    try {
      setLoading(true);
      await handleFetchEvents();
      let promises = [];
      parcelId &&
        checkboxOptions.forEach(group => {
          promises.push(
            fetchTimeseries({
              parcelId,
              sensor: group.sensor,
              names: group.stats.map(stat => stat.name),
            })
          );
        });
      const timeseries = await Promise.all(promises);

      setTimeseries(timeseries);
      setOpenControls(true);
      setOpenBottomDrawer(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  });

  React.useEffect(() => {
    if (activeTile) {
      setSelectedDate(false);
      handleFetchDates();
      handleFetchCloud();
      setOpenControls(true);
    }
    // eslint-disable-next-line
  }, [activeTile]);

  React.useEffect(() => {
    // may be troublesome
    console.log('# react app environment:', process.env.REACT_APP_ENV);
    window.dispatchEvent(new Event('resize'));
  }, [openDrawer]);

  React.useEffect(() => {
    parcelId && handleFetchTimeseries();
    // eslint-disable-next-line
  }, [parcelId]);

  const handleFetchDates = async () => {
    try {
      const response = await fetchTimelineDates({
        sensor: rasterType.querySensor,
        tile: activeTile,
        band: rasterType.queryBand,
      });
      if (!response) throw Error(`Request rejected`);

      const response2020 = {
        datasets: response.datasets.filter(
          ({ date }) => parseInt(date) > 20200000 && date
        ),
      };
      setDates(response2020);
    } catch (error) {
      console.error(error);
      Sentry.captureMessage(error);
    }
  };

  const handleFetchCloud = async () => {
    try {
      const response = await fetchCloudPercentages({
        stat: 'cloudfreeofobservedlanddk',
        sensor: 's2',
        tile: activeTile,
      });
      if (!response) throw Error(`Request rejected`);
      setCloudPercentages(response);
    } catch (error) {
      console.error(error);
      Sentry.captureMessage(error);
    }
  };

  const handleFindParcels = async ({ lat, lon }) => {
    try {
      enqueueSnackbar(`Fetching parcel data.`, {
        variant: 'info',
      });
      setLoading(true);

      const parcelsResponse = await fetchParcels({
        lat,
        lon,
        layer: parcelLayer.layer,
        workspace: parcelLayer.workspace,
      });
      if (!parcelsResponse) throw Error(`Request rejected`);
      const { foi_id, tile } = parcelsResponse.features[0].properties;

      handleViewport({
        ...viewport,
        latitude: (parcelsResponse.bbox[1] + parcelsResponse.bbox[3]) / 2,
        longitude: (parcelsResponse.bbox[0] + parcelsResponse.bbox[2]) / 2,
      });

      setParcels(parcelsResponse);
      setParcelId(foi_id);
      setActiveTile(tile);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      enqueueSnackbar(
        `No parcel found at this location. Please click again or search by parcel ID.`,
        {
          variant: 'error',
        }
      );
      Sentry.captureMessage(error);
    }
  };

  const handleToggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleFetchData = async () => {
    try {
      setLoading(true);
      enqueueSnackbar(`Fetching data for ID ${searchField}.`, {
        variant: 'info',
      });

      const parcelsResponse = await fetchData({
        query: searchField.includes('-')
          ? `FS_JNR='${searchField}'`
          : `foi_id=${searchField}`,
        workspace: parcelLayer.workspace,
        layer: parcelLayer.layer,
      });
      if (!parcelsResponse) throw Error(`Request rejected`);

      const tileResponse = await fetchTile({
        parcelId: searchField.includes('-')
          ? parcelsResponse.features[0].properties.foi_id
          : searchField,
      });
      if (!tileResponse) throw Error(`Request rejected`);

      handleViewport({
        ...viewport,
        latitude: (parcelsResponse.bbox[1] + parcelsResponse.bbox[3]) / 2,
        longitude: (parcelsResponse.bbox[0] + parcelsResponse.bbox[2]) / 2,
        zoom: 11.5,
      });

      setActiveTile(tileResponse.tile);
      setParcelId(parcelsResponse.features[0].properties.foi_id);
      setParcels(parcelsResponse);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
      enqueueSnackbar(`No data for ID ${searchField}.`, {
        variant: 'error',
      });
      Sentry.captureMessage(error);
    }
  };

  return (
    <AppContext.Provider
      value={{
        state: {
          activeMarker,
          activeTab,
          activeTile,
          classificationOpacity,
          cloudPercentages,
          dates,
          legend,
          mapbox,
          markers,
          openDrawer,
          parcelId,
          parcelLayer,
          parcels,
          rasterOptions,
          rasterType,
          searchField,
          selectedDate,
          showTiles,
          timeseries,
          viewport,
        },
        actions: {
          handleFetchData,
          handleFetchEvents,
          handleFetchTimeseries,
          handleToggleDrawer,
          handleViewport,
          setActiveMarker,
          setActiveTile,
          setClassificationOpacity,
          setLegend,
          setOpenBottomDrawer,
          setParcelId,
          setParcelLayer,
          setRasterType,
          setSearchField,
          setSelectedDate,
          setShowTiles,
          setTab,
          setTimeseries,
          setViewport,
        },
      }}
    >
      <div
        style={{
          width: '100vw',
          maxWidth: '100vw',
          height: window.innerHeight,
        }}
      >
        <Loader isLoading={loading} isMajorLoading={loading} />

        <Box display="flex" height={1} width={1}>
          <Box flexGrow={1}>
            <Box height={openBottomDrawer ? '55%' : '100%'}>
              <Box height={`100%`}>
                <Map
                  handleFindParcels={handleFindParcels}
                  handleViewport={handleViewport}
                  parcels={parcels}
                  showTiles={showTiles}
                  setActiveTile={setActiveTile}
                  setMapbox={setMapbox}
                  setViewport={setViewport}
                  viewport={viewport}
                />
              </Box>
              {openControls && (
                <Box style={{ position: 'relative', bottom: 146, height: 124 }}>
                  <MapControls />
                </Box>
              )}
            </Box>

            {openBottomDrawer && (
              <Box
                height={'45%'}
                width={1}
                boxSizing="border-box"
                style={{ borderTop: '1px solid #DBE4E9' }}
              >
                <BottomDrawer />
              </Box>
            )}
          </Box>

          {openDrawer && (
            <Box width={360} style={{ borderLeft: `1px solid ${grey.medium}` }}>
              <Sidebar activeTab={activeTab} />
            </Box>
          )}
          {/* <Box width={36} style={{ borderLeft: `1px solid ${grey.medium}` }}>
            <SidebarControl />
          </Box> */}
        </Box>
      </div>
    </AppContext.Provider>
  );
};

export default App;
