import React from 'react';
import { Typography } from '@material-ui/core';

const infoProducts = [
  {
    id: 'backgroundInfo',
    title: 'Background',
    active: true,
    description: (
      <>
        <Typography variant="body2">
          Seas and coasts are considered key drivers of the economy (Blue
          Growth) by the EU and OECD. Through sustainable use of the marine
          environment, we ensure a continued contribution to human welfare,
          whereas overexploitation have severe consequences for the natural
          capital.
        </Typography>
        <Typography variant="body2">
          The key to sustainable human use of marine resources is precise
          knowledge of existing conditions and accurate predictions of
          anthropogenic pressures. SeaStatus combines innovative monitoring with
          new models for decision-making and finally to support sustainable use
          of the marine environment by improving the knowledge base and
          predictability of management scenarios. Here we present
          satellite-based products used in the SeaStatus activity over fjord
          systems in Denmark.
        </Typography>
        <Typography variant="body2">
          The project is funded with 10 million DKK from the Danish Innovation
          Fund (IFD).
        </Typography>
      </>
    ),
  },
  {
    id: 'CHL',
    title: 'Chlorophyll',
    active: false,
    description: (
      <>
        <Typography variant="body2">
          Chlorophyll is used as a proxy of water quality and is related to the
          algal biomass in the water. Rapid algal growth is mainly triggered by
          high levels of nutrients (e.g., nitrogen, phosphate). High chlorophyll
          concentrations are therefore usually indicative for poor water
          quality, i.e. high nutrient levels.
        </Typography>
        <Typography variant="body2">
          Excessive algal growth can cause aesthetic problems such as green
          scums and bad odors, but it also decreases the amount of oxygen in the
          water. A lack of oxygen can have severe consequences for all aquatic
          life, which needs sufficient amounts of oxygen dissolved in water to
          survive. Some algae also produce elevated toxins and bacterial growth
          that can have harmful effects on people and marine life when found in
          high concentrations (cf. harmful algal blooms).
        </Typography>
        <Typography variant="body2">
          Chlorophyll concentrations are highly variable, either seasonally or
          on an annual basis due to the species composition, light conditions
          and nutrient availability. Spring blooms, for instance, occur on an
          annual basis and are characterized by very high chlorophyll
          concentrations, typically seen in the early spring in Danish waters.
        </Typography>
      </>
    ),
  },
  {
    id: 'TSM',
    title: 'Total Suspended Matter',
    active: false,
    description: (
      <>
        <Typography variant="body2">
          Total Suspended Matter (TSM) is a measure of water clarity and
          indicates how many suspended particles are found in the water. TSM (or
          turbidity) affects the amount of light that reaches into the water.
          The more sediment and other particles in the water, the less light
          will be able to penetrate and with less light available, plant
          photosynthesis and hence biomass production will decrease.
        </Typography>
        <Typography variant="body2">
          TSM can include organic (detritus, phytoplankton, zooplankton) or
          inorganic (sediment particles, diatom frustules) material and can
          originate from riverine discharge, advection or be resuspended
          locally. TSM can be alive (phytoplankton, zooplankton) or dead
          (detritus, sediment).
        </Typography>
      </>
    ),
  },
];

export { infoProducts };
