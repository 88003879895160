import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import DHITheme from 'styles/theme';
import MarkerIcon from './partials/MarkerIcon';
import LineIcon from './partials/LineIcon';
import { AppContext } from 'App';

const { grey, primary } = DHITheme.palette;

const TimeseriesControl = ({ checkboxes, setCheckboxes }) => {
  const {
    actions: { setOpenBottomDrawer },
  } = React.useContext(AppContext);

  const handleCheckbox = (i, j) => {
    let obj = checkboxes;
    obj[i].stats[j].checked = !obj[i].stats[j].checked;
    setCheckboxes([...obj]);
  };

  return (
    <>
      <Box style={{ borderBottom: `1px solid ${grey.medium}` }} px={2} py={1}>
        <Grid container>
          <Grid item xs={10}>
            <Typography
              variant="subtitle1"
              style={{ color: `${primary.main}`, fontSize: 14 }}
            >
              Timeseries control
            </Typography>
          </Grid>
          <Grid item xs={2} style={{ cursor: 'pointer' }}>
            <Typography
              variant="subtitle1"
              style={{
                color: `${primary.main}`,
                fontSize: 14,
                textAlign: 'right',
              }}
              onClick={() => setOpenBottomDrawer(false)}
            >
              Close
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box px={2} py={1}>
        {checkboxes &&
          checkboxes.map((group, i) => (
            <Box
              key={i}
              style={{ borderBottom: `1px solid ${grey.medium}` }}
              py={1}
            >
              <Box width={1}>
                <Typography
                  variant="subtitle1"
                  style={{ color: `${primary.main}` }}
                >
                  {group.title}
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap">
                {group.stats.map(({ checked, color, mode, title }, j) => (
                  <Box
                    key={j}
                    display="flex"
                    onClick={() => handleCheckbox(i, j)}
                    style={{ cursor: 'pointer' }}
                    width={64}
                  >
                    {mode === 'markers' ? (
                      <MarkerIcon checked={checked} color={color} />
                    ) : (
                      <LineIcon checked={checked} color={color} />
                    )}

                    <Box pt={0.5}>
                      <Typography
                        variant="body2"
                        style={{
                          color: checked ? `${primary.main}` : `${grey.dark}`,
                        }}
                      >
                        {title}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
      </Box>
    </>
  );
};

export default TimeseriesControl;
