import React from 'react';
import {
  Box,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from '@material-ui/core';
import { About, Contact, Disclaimer, FAQ, Privacy, Terms } from './partials';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DHITheme from 'styles/theme';

const panels = [
  {
    title: 'FAQ',
    component: FAQ,
  },
  {
    title: 'About',
    component: About,
  },
  {
    title: 'Contact',
    component: Contact,
  },
  {
    title: 'Disclaimer',
    component: Disclaimer,
  },

  {
    title: 'Privacy',
    component: Privacy,
  },
  {
    title: 'Terms',
    component: Terms,
  },
];

export default function Support() {
  const [expanded, setExpanded] = React.useState();
  const { primary } = DHITheme.palette;

  const handleChange = panel => (e, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box>
      <Typography variant="h6">Support</Typography>
      {panels.map(({ title, component: Component }) => (
        <ExpansionPanel
          key={title}
          expanded={expanded === title}
          onChange={handleChange(title)}
        >
          <ExpansionPanelSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: primary.main, padding: 4 }} />
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Typography variant="h5" style={{ fontSize: 12 }}>
              {title}
            </Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Component />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      ))}
    </Box>
  );
}
