import React from 'react';
import { Box, Typography } from '@material-ui/core';

const FAQ = () => {
  return (
    <Box>
      <Typography variant="subtitle1">
        Can I get such information from my area?
      </Typography>
      <Typography variant="body2">
        Yes satellites and our services are global!
      </Typography>
      <Typography variant="subtitle1">
        How often can I get this type of maps?
      </Typography>
      <Typography variant="body2">
        Potentially daily (and in some cases a few times per day), but this
        depends on the overpass schedule of the different satellites.
      </Typography>
      <Typography variant="subtitle1">
        Is it possible to also get historical information on the water quality
        parameters?
      </Typography>
      <Typography variant="body2">
        Yes! We have access to all relevant archives and can provide global
        information more than 20 years back in time.
      </Typography>
      <Typography variant="subtitle1">What costs are associated?</Typography>
      <Typography variant="body2">
        Use of the viewer is free. Access to data (API access, download or FTP
        push/pull) can be arranged so it matches any client side technical
        requirements. Please contact us for a detailed and custom quote.
      </Typography>
      <Typography variant="subtitle1">
        Is it possible to get information in higher detail?
      </Typography>
      <Typography variant="body2">
        Yes! We can provide global water quality information in 10m resolution
        similar to the products shown on the portal. The data is potentially
        available every 2-3 days (depends on your exact location).
      </Typography>
      <Typography variant="subtitle1">
        Can I get information on cloudy days?
      </Typography>
      <Typography variant="body2">
        Unfortunately not. The approach relies on optical data so cloud cover
        obstructs the satellite view of the sea surface. We have added 10-day
        composites (showing aggregated information from the past 10-days) so
        illustrate how the general conditions can be illustrated even in partly
        cloudy periods.
      </Typography>

      <Typography variant="subtitle1">How accurate is the data?</Typography>
      <Typography variant="body2">
        This may vary from place to place, depending on the specific physical
        conditions of an area. From past studies we have observed absolute
        accuracy levels around +/- 30% compared to traditional in-situ samples.
        With dedicated calibration campaigns the products can be locally
        optimized for maximum output accuracies.
      </Typography>
      <Typography variant="subtitle1">
        I am interested in derived information such as areal/seasonal
        statistics, estimations of ecological status, p90/max concentrations
        etc. Is that possible?
      </Typography>
      <Typography variant="body2">
        Yes! We can derive any kind of descriptive statistics and custom outputs
        tailored our specific needs.
      </Typography>
    </Box>
  );
};

export default FAQ;
